
import { api } from "@/api/api";
import { ApiGetCourseDto, ApiGetCurriculumDto, ApiGetGrantRateDto } from "@/api/generated/Api";
import { hasMemberOrgAccess, useRestrictedAccessApi } from "@/shared/helpers/accessLevelApiAdapter";
import { calculateAmountByGrantId } from "@/shared/helpers/courseHelpers";
import { formatDate, getTimeOfDay } from "@/shared/helpers/dateHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { capitalize } from "@/shared/helpers/textHelpers";
import { useRoute } from "@/shared/useHelpers";
import { computed, defineComponent, onMounted, PropType, ref, watch } from "@vue/composition-api";
import getYear from "date-fns/fp/getYear";
import ScheduleBanners from "../new/steps/plan/ScheduleBanners.vue";
import { ClientAccountInvoiceType } from "@/shared/enums/ClientAccountInvoiceType.enum";

interface InformationCardItem {
  title: string;
  value?: string | number | string[] | null;
}

export default defineComponent({
  components: { ScheduleBanners },
  name: "CourseCard",
  props: {
    course: {
      type: Object as PropType<ApiGetCourseDto>,
      required: true,
    },
    isCalendarValid: {
      type: Boolean,
      required: true,
    },
    curriculum: {
      type: Object as PropType<ApiGetCurriculumDto>,
      required: true,
    },
    maxHours: {
      type: Number,
      required: true,
    },
    validationState: {
      type: Object as PropType<Record<string, boolean>>,
      required: false,
    },
    isVocationalSchool: {
      type: Boolean,
      required: true,
    },
  },

  setup(props) {
    const route = useRoute();
    const expansionPanels = ref(0);
    const courseInformationCard = ref<InformationCardItem[]>();
    const detailsCard = ref<InformationCardItem[]>();
    const hoursInformationCard = ref<InformationCardItem[]>();
    const economyInformationCard = ref<InformationCardItem[]>();
    const restrictedAccessApi = useRestrictedAccessApi();
    const currentGrantRate = ref<ApiGetGrantRateDto>();

    const isGrantAmount = computed<boolean>(
      () => props.course.economy?.grantId !== undefined && route.path.includes("/endCourse")
    );

    const teachingGrantAmount = computed(() =>
      calculateAmountByGrantId(
        props.course.economy?.grantId || 0,
        props.course.hoursWithInstructor || 0,
        currentGrantRate.value?.teachingGrantAmount || 0,
        currentGrantRate.value?.extraProfessionalCourseAmount || 0
      )
    );

    const getCurrentAdress = async () => {
      if (!props.course.courseLocation?.areaId) {
        return;
      }
      const area = (await restrictedAccessApi.getAreaById(props.course.courseLocation.areaId)).data;
      if (!props.course.courseLocation.locationId || hasMemberOrgAccess) {
        return { area: area.place };
      }

      const locationResponse = (
        await api.area.getLocationAsync(props.course.courseLocation.areaId, props.course.courseLocation.locationId)
      ).data;
      return {
        area: area.place,
        street: locationResponse.street,
        postalCode: locationResponse.postalCode,
        postLocation: locationResponse.postalArea,
      };
    };

    const getContactUserName = async (id: number) => {
      if (hasMemberOrgAccess) {
        return;
      }
      const { firstName, lastName } = (await api.user.getUserAsync(id)).data;
      return `${firstName} ${lastName}`;
    };

    const getCurrentGrant = async () => {
      if (!props.course.economy?.grantId || hasMemberOrgAccess) {
        return;
      }
      const currentYear = getYear(new Date(props.course.startDate));
      const allGrantRates = hasMemberOrgAccess ? [] : (await api.economy.getGrantRates()).data;
      currentGrantRate.value = allGrantRates.find((grantRate) => grantRate.year === currentYear) ?? undefined;
    };

    const getStudyLocationNameById = async (id?: number) => {
      if (id === undefined || hasMemberOrgAccess) {
        return "-";
      }
      const studyLocations = (await api.studylocation.getStudyLocationsPerOrganizationAsync()).data;
      return studyLocations.find((sl) => sl.id === id)?.name ?? "Ukjent studiested";
    };

    const getCourseInformationCard = () => {
      globalLoadingWrapper({ blocking: false }, async () => {
        if (!props.course || !props.curriculum) {
          return;
        }
        const [adress, contactPerson, courseSupervisor, lecturer, studyLocationName] = await Promise.all([
          getCurrentAdress(),
          getContactUserName(props.course.contactId),
          getContactUserName(props.course.courseSupervisorId),
          getContactUserName(props.course.lecturerId),
          getStudyLocationNameById(props.course.studyLocationId ?? undefined),
          getCurrentGrant(),
        ]);

        if (!adress) {
          return;
        }

        courseInformationCard.value = [
          { title: "Status", value: capitalize(props.course.status || "") },
          ...(props.isVocationalSchool ? [{ title: "Studiested", value: studyLocationName }] : []),
          { title: "Lokasjon", value: adress?.area || "" },
          ...(adress.street ? [{ title: "Adresse", value: adress.street }] : []),
          ...(adress.postalCode && adress.postLocation
            ? [{ title: "Postnr./-sted", value: `${adress.postalCode}, ${adress.postLocation}` }]
            : []),
          { title: "Studieplan-ID", value: props.curriculum.id },
          { title: "Studieplan", value: props.curriculum.name },
          { title: "Startdato", value: formatDate(props.course.startDate) },
          { title: "Sluttdato", value: formatDate(props.course.endDate) },
          { title: "Start klokkeslett", value: getTimeOfDay(new Date(props.course.startDate)) },
          { title: "Slutt klokkeslett", value: getTimeOfDay(new Date(props.course.endDate)) },
          ...(courseSupervisor
            ? [
                {
                  title: props.isVocationalSchool ? "Studieansvarlig" : "Kursansvarlig",
                  value: courseSupervisor || "Fant ikke person",
                },
              ]
            : []),
          ...(lecturer
            ? [
                {
                  title: props.isVocationalSchool ? "Studieadministrator" : "Kursadministrator",
                  value: lecturer || "Fant ikke person",
                },
              ]
            : []),
          ...(contactPerson ? [{ title: "Kontaktperson", value: contactPerson || "Fant ikke person" }] : []),
          ...(isGrantAmount.value ? [{ title: "Opplæringstilskudd", value: teachingGrantAmount.value }] : []),
        ];
      });
    };

    const getFinanciers = (organizationIds?: number[] | null) => {
      if (!organizationIds || hasMemberOrgAccess) {
        return;
      }
      const financedByOrganizationNames = organizationIds.map(
        async (id) => (await api.customer.getCustomerOrgByIdAsync(id)).data.customer.name
      );
      return Promise.all(financedByOrganizationNames);
    };

    const getFormTemplate = async (templateId?: string) => {
      if (!templateId) {
        return;
      }
      return (await restrictedAccessApi.getAppFormTemplate(templateId)).data.name;
    };

    const getDetailsCard = () => {
      globalLoadingWrapper({ blocking: false }, async () => {
        if (!props.course || !props.curriculum) {
          return;
        }
        const [financiers, formTemplateName] = await Promise.all([
          getFinanciers(props.course.financedByOrganizationIds),
          getFormTemplate(props.course.webDescription?.templateId),
        ]);

        let updatedByResponse;

        if (!hasMemberOrgAccess) {
          updatedByResponse = (await api.user.getUserAsync(props.course.updatedBy)).data || "";
        }

        detailsCard.value = [
          ...(props.course?.enrollmentDeadline
            ? [{ title: "Påmeldingsfrist", value: formatDate(props.course.enrollmentDeadline) }]
            : []),

          ...(props.course?.unenrollmentDeadline
            ? [{ title: "Avmeldingsfrist", value: formatDate(props.course.unenrollmentDeadline) }]
            : []),
          ...(props.course?.financedByOrganizationIds
            ? [{ title: "Finansiert av", value: financiers?.join(", ") ?? "" }]
            : []),

          ...(props.course?.webDescription?.webCategories
            ? [{ title: "Webkategori", value: props.course.webDescription.webCategories.join(", ") }]
            : []),

          ...(props.course?.externalTitle ? [{ title: "Eksterntittel", value: props.course.externalTitle }] : []),

          ...(props.course?.webDescription?.publishDate
            ? [{ title: "Publisert dato", value: formatDate(props.course.webDescription.publishDate) }]
            : []),

          ...(props.course.updated ? [{ title: "Sist oppdatert", value: formatDate(props.course.updated) }] : []),

          ...(updatedByResponse
            ? [{ title: "Oppdatert av", value: `${updatedByResponse.firstName} ${updatedByResponse.lastName}` }]
            : []),

          ...(props.course?.webDescription?.unpublishDate
            ? [{ title: "Avpubliseringsdato", value: formatDate(props.course.webDescription.unpublishDate) }]
            : []),

          ...(props.course?.webDescription?.templateId ? [{ title: "Påmeldingsskjema", value: formTemplateName }] : []),
        ];
      });
    };

    const getHoursInformationCard = () => {
      hoursInformationCard.value = [
        { title: "Timer m/lærer", value: props.course.hoursWithInstructor || 0 },
        { title: "Timer u/lærer", value: props.course.hoursWithoutInstructor || 0 },
        { title: "Antall timer gjennomført", value: props.course.completedScheduledHours },
        { title: "Antall timer gjenstående", value: props.course.remainingScheduledHours },
      ];
    };

    const getEconomyDetailsCard = () => {
      economyInformationCard.value = [
        { title: "Tilskuddskode", value: props.course.economy?.grantId || 0 },
        { title: "Artikkelnummer", value: props.course.economy?.articleNos?.join(", ") || 0 },
        { title: "Regnskapsavdeling", value: props.course.economy?.accountingDepartment },
        { title: "Kursavgift", value: props.course.economy?.price },
        { title: "Avbestillingsgebyr", value: props.course.economy?.cancellationFee },
        { title: "Tillater Reise/diett", value: props.course.economy?.allowExpenses ? "Ja" : "Nei" },
      ];
      if (props.course.clientAccountInvoiceType) {
        economyInformationCard.value.push({
          title: "Fakturagrunnlagtype",
          value:
            props.course.clientAccountInvoiceType === ClientAccountInvoiceType.AccountingDepartment
              ? "Regnskapsavdeling"
              : props.course.clientAccountInvoiceType === ClientAccountInvoiceType.Central
              ? "Sentral"
              : "Lokal",
        });
      }
    };

    onMounted(async () => {
      globalLoadingWrapper({ blocking: false }, async () => {
        getCourseInformationCard();
        getHoursInformationCard();
        getDetailsCard();
        getEconomyDetailsCard();
      });
    });

    watch(
      () => props.isCalendarValid,
      (newValue) => {
        if (newValue === false) {
          expansionPanels.value = 1;
        }
      },
      { immediate: true }
    );

    return {
      expansionPanels,
      courseInformationCard,
      hoursInformationCard,
      isGrantAmount,
      detailsCard,
      economyInformationCard,
    };
  },
});
